import { Search } from '@vakantiesnl/types/src';
import dayjs from 'dayjs';

import { IS_ACCO_ATTRIBUTES_ENABLED } from '../constants';
import { prikdatum as prikdatumManifest } from '../route-params';
import { FilterState, getSelectedDurations } from '../stores/filtersStore';
import { GlobalFiltersState } from '../stores/globalFiltersStore';
import {
	getDepartureDateOffset,
	departureDateFilterValueOrDefault,
	getMinDepartureDate,
	getDateYMD,
} from '../util/dateUtils';
import { formatDurationsSearchQuery } from '../util/durations';

/**
 * Define the specific TUI tour operators, currently there are multiple TO's for TUI
 * Only needs to be send in the request body
 */

const TUI_TO_OPERATORS = ['TWDPKGT', 'TWDPKG'];

export const initBaseSearchReqParams = (
	filters: FilterState,
	globalFilters: GlobalFiltersState,
	isFlashDeals = false,
): Search.VaknlSearchBody => {
	const searchBody: Search.VaknlSearchBody = {
		brand: process.env.NEXT_PUBLIC_BRAND,
		party: globalFilters.party[0],
		rooms: globalFilters.party,
		page: filters.page,
		page_size: filters.size,
		modifiers: {
			sorting_order: filters.sort,
		},
		filters: {},
	};

	const isOwnTransport = filters.transport === 'OWN';

	const centsInEuro = 100; // BE handles min & max price in cents.

	if (!isOwnTransport) {
		if (filters.priceMin) {
			const min = parseInt(filters.priceMin, 10);
			min && (searchBody.min_price = min * centsInEuro);
		}

		if (filters.priceMax) {
			const max = parseInt(filters.priceMax, 10);
			max && (searchBody.max_price = max * centsInEuro);
		}

		if (filters.mealplans.length) {
			searchBody.filters.mealplans = filters.mealplans;
		}
	}

	if (filters.departureDate?.length && filters.flexibleDepartureDate) {
		searchBody.departure_date = filters.departureDate[0];

		const offset = getDepartureDateOffset(new Date(filters.departureDate[0]), filters.flexibleDepartureDate);

		searchBody.pre_offset = offset.pre;
		searchBody.post_offset = offset.post;
	} else if (filters.departureDate?.length) {
		const [rawMinDate, rawMaxDate] = filters.departureDate;
		const isRangeSelected = rawMinDate && rawMaxDate;

		if (isRangeSelected) {
			const minDate = dayjs(rawMinDate);
			const maxDate = dayjs(rawMaxDate);
			// If somehow dateRange order is mixed up at this point, just set pre_offset to 0
			const preOffset = minDate.isBefore(maxDate) ? maxDate.diff(minDate, 'days') : 0;

			searchBody.departure_date = rawMaxDate;
			searchBody.pre_offset = preOffset;
		} else {
			searchBody.departure_date = rawMinDate;
		}
	} else if (!filters.isDealsRoute) {
		// When no departure date has been set and a prikdatum has been defined, default to the prikdatum when
		// this date is after minDepartureDate with a post_offset of 100 days
		const prikdatum = dayjs(prikdatumManifest.date, 'YYYY-MM-DD');
		const minDepartureDate = getMinDepartureDate();

		const hasPrikdatumExcludedDomain = filters.domain
			? prikdatumManifest.excludedDomains.includes(filters.domain)
			: false;
		const hasPrikdatumExcludedCampaign = filters.campaign
			? prikdatumManifest.excludedCampaigns?.includes(filters.campaign)
			: false;

		const hasPrikdatumSupport = !hasPrikdatumExcludedDomain && !hasPrikdatumExcludedCampaign;

		// todo: update this?
		if (prikdatum.isValid() && hasPrikdatumSupport && minDepartureDate.isSameOrBefore(prikdatum)) {
			searchBody.departure_date = getDateYMD(prikdatum);
			searchBody.pre_offset = 0;
			searchBody.post_offset = parsePostOffset(prikdatumManifest.postOffset);
		}
	}

	const durations = getSelectedDurations(globalFilters, filters);
	const formattedDurations = formatDurationsSearchQuery(durations);

	if (formattedDurations) {
		searchBody.filters.durations_flight = formattedDurations;
	}

	if (IS_ACCO_ATTRIBUTES_ENABLED && !isFlashDeals) {
		if (filters.attributes.length || filters.themes.length) {
			searchBody.filters.attributes = [...filters.attributes, ...filters.themes];
		}
	} else {
		if (filters.themes.length) {
			searchBody.filters.themes = filters.themes;
		}
	}

	if (filters.departureAirport.length) {
		searchBody.filters.departure_airports = filters.departureAirport;
	}

	/** Specific filters are added to some requests for domains */
	if (filters.domain?.length) {
		searchBody.filters.domains = [filters.domain];

		if (['city_trip'].includes(filters.domain)) {
			searchBody.departure_date = departureDateFilterValueOrDefault(
				filters.departureDate?.length ? getDateYMD(new Date(filters.departureDate[0])) : '',
				getMinDepartureDate().toDate(),
			);
		}
	}

	if (filters.giata_ids?.length) {
		searchBody.giata_ids = filters.giata_ids;
	}
	if (filters.tourOperators?.length) {
		// Use reduce to iterate over tourOperators, accumulating unique operators
		// and including all TUI_TO_OPERATORS if any one of them is found
		const filtered_tour_operators = filters.tourOperators.reduce((acc, op) => {
			// Add current operator to the Set
			acc.add(op.tour_operator);
			// If the current operator is one of the TUI_TO_OPERATORS, add all of them to the Set
			if (TUI_TO_OPERATORS.includes(op.tour_operator)) {
				TUI_TO_OPERATORS.forEach((tuiOp) => acc.add(tuiOp));
			}
			return acc;
		}, new Set<string>()); // Use a Set to automatically ensure all values are unique

		// Convert the Set to an array and assign it to the searchBody filters
		searchBody.filters.tour_operators = [...filtered_tour_operators];
	}

	return searchBody;
};

function parsePostOffset(value: unknown): number {
	const number = Number(value);

	if (Number.isInteger(number) && number >= 0) {
		return number;
	}

	return 0;
}

export const initVaknlSearchReqParams = (
	filters: FilterState,
	globalFilters: GlobalFiltersState,
	isFlashDeals = false,
): Search.VaknlSearchBody => {
	const searchBody = initBaseSearchReqParams(filters, globalFilters, isFlashDeals);

	if (filters.countrySlugs?.length > 0) {
		searchBody.filters.geo_filters = {
			...searchBody.filters.geo_filters,
			country_slugs: filters.countrySlugs,
		};
	}

	if (filters.regionSlugs?.length > 0) {
		searchBody.filters.geo_filters = {
			...searchBody.filters.geo_filters,
			region_slugs: filters.regionSlugs,
		};
	}

	if (filters.citySlugs?.length > 0) {
		searchBody.filters.geo_filters = {
			...searchBody.filters.geo_filters,
			city_slugs: filters.citySlugs,
		};
	}

	if (filters.awards.length) {
		searchBody.filters.awards = filters.awards;
	}
	if (filters.chains?.length > 0) {
		searchBody.filters.chains = filters.chains.map((chain) => chain.entityId);
	}

	return searchBody;
};
